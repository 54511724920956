import reactStringReplace from 'react-string-replace';
import { Emoji } from './Emoji';
import { SERVER_BASE_URL } from './App';






export function processMessageContent(content) {
	if (!content) return;

	// youtube
	content = reactStringReplace(content, /https?:\/\/(?:(?:www.)?youtube.com|youtu.be)\/(?:watch\?v=)?([a-zA-Z0-9-_]{11})/gi, id => <iframe className="inline-block align-top max-w-[560px] max-h-[315px] w-full h-full aspect-video" src={`https://www.youtube-nocookie.com/embed/${id}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>);

	// hyperlinks
	content = reactStringReplace(content, /(https?:\/\/\S+)/gi, link => <a href={link} target="_blank" rel="noopener" style={{ color: "revert", textDecoration: "revert" }}>{link}</a>
	);

	// emoji
	content = reactStringReplace(content, /:([a-z0-9-_]+):/gi, emoji => <Emoji emoji={emoji} />);

	// files
	content = reactStringReplace(content, /\[img:(\S+)\]/gi, x => {
		var url = SERVER_BASE_URL + "/objects/" + x;
		return <a href={url} target="_blank" rel="noopener">
			<img src={url} alt="" className="max-h-32 inline-block align-top border" />
		</a>;
	});
	content = reactStringReplace(content, /\[video:(\S+)\]/gi, x => {
		var url = SERVER_BASE_URL + "/objects/" + x;
		return <video className='max-h-64 inline-block align-top border' controls>
			<source src={url} />
		</video>;
	});
	content = reactStringReplace(content, /\[audio:(\S+)\]/gi, x => {
		var url = SERVER_BASE_URL + "/objects/" + x;
		return <audio className='max-h-64 inline-block align-top' controls>
			<source src={url} />
		</audio>;
	});
	content = reactStringReplace(content, /\[file:(\S+)\]/gi, x => {
		var url = SERVER_BASE_URL + "/objects/" + x;
		var filename = x.split('/').pop() || x;
		return <a href={url} target="_blank" rel="noopener">{filename}</a>;
	});

	return content;
}
