import { useEffect, useState, memo } from 'react';
import { SERVER_BASE_URL } from './App';






export var SettingsModal = memo(function SettingsModal({ open, setOpen, user, updateUser, theme, setTheme }) {
	var [emojis, setEmojis] = useState([]);
	async function getEmojis() {
		var emojis = await (await fetch(SERVER_BASE_URL + "/emojis")).json();
		return emojis;
	}
	useEffect(function () {
		if (open) {
			getEmojis().then(setEmojis);
		}
	}, [open]);


	function EmojiUploader({ setEmojis }) {
		var [file, setFile] = useState(null);
		var [name, setName] = useState("");
		async function onSubmit(event) {
			event.preventDefault();
			if (!name || !file) return;
			var _file = file; setFile(null);
			var _name = name; setName("");
			var res = await fetch(SERVER_BASE_URL + "/emoji/" + _name + "?type=" + encodeURIComponent(_file.type), {
				method: "PUT",
				body: await _file.arrayBuffer()
			});
			if (res.status === 201) setEmojis(emojis => [...emojis, _name]);
			else alert(await res.text());
		}
		return <form onSubmit={onSubmit}>
			<input type="file" onChange={event => setFile(event.target.files[0])} />
			<input type="text" placeholder='Emoji name' className="dark:text-black  border" value={name} onChange={event => setName(event.target.value.replace(/[^a-z0-9-_]/gi, ''))} />
			<input type="submit" className="border" />
		</form>;
	}

	if (open) return <div className="fixed top-0 left-0 w-full h-full bg-slate-500/50 flex justify-center items-center" onClick={e => setOpen(false)}>
		<div className="bg-white dark:bg-black border rounded-lg w-8/12 p-8 h-4/6 overflow-auto" onClick={e => e.stopPropagation()}>
			<div className="text-2xl border-b border-slate-500 mb-1">Settings</div>
			<div className="m-3"><label>Name: <input type="text" className="dark:text-black border" value={user.name} onChange={e => updateUser({ name: e.target.value })} /></label></div>
			<div className="m-3"><label>Color: <input type="color" value={user.color} onChange={e => updateUser({ color: e.target.value })} /></label></div>
			<div className="m-3"><label>Website: <input type="text" className="dark:text-black border" placeholder='http://example.com' value={user.website} onChange={e => updateUser({ website: e.target.value })} /></label></div>
			<div className="m-3"><label>Theme: <select className="dark:text-black border" value={theme} onChange={event => setTheme(event.target.value)}>
				<option value="light">Light</option>
				<option value="dark">Dark</option>
				<option value="">System</option>
			</select></label></div>
			<div className="text-2xl mt-8 border-b border-slate-500 mb-2">Emoji</div>
			{emojis.map(emoji => <img src={SERVER_BASE_URL + "/emoji/" + emoji} title={`:${emoji}:`} alt={`:${emoji}:`} key={emoji} className="w-8 h-8 inline-block m-1" />)}
			<div className="text-xl mt-8">Upload Emoji</div>
			<EmojiUploader setEmojis={setEmojis} />
		</div>
	</div>;
});
