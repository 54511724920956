import { useState } from 'react';
import { processMessageContent } from './util';






export function Message({ message }) {
	var [hover, setHover] = useState(false);

	if (message.user) {
		var prefix = <b>{message.user?.website ? <a href={message.user.website} target="_blank" rel="noopener" className={message.user.website && "hover:underline"}>{message.user.name}</a> : message.user.name}: </b>;
	}
	var content = <span className={"content" + (message.user ? '' : " font-bold")} style={{ color: message.user?.color }} title={new Date(message.timestamp).toLocaleString()}>{processMessageContent(message.content)}</span>;

	if (hover) {
		if (message.user?.you) var manageButtons = <><button>🗑</button><button>✏</button></>;
		var options = <span className="absolute top-0 right-0 mr-4 text-gray-500 text-xs italic">{manageButtons}<button onClick={() => global.appendToTextInput(`@${message.user?.name}:“${message.content}”>>`)}>↩️</button> - {new Date(message.timestamp).toLocaleString()}</span>;
	}

	return <li id={message._id} className="px-4 relative" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} style={{
		backgroundColor: hover ? "rgb(127 127 127 / 15%)" : undefined
	}}>{prefix} {content} {options}</li>;
}
