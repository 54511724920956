




export function MousingLayer({ mice: miceData, users }) {
	var mice = [];
	for (let socketid in miceData) {
		let user = users.find(user => user.socketid === socketid);
		let mouse = miceData[socketid];
		mice.push(<div key={socketid} className="fixed" style={{
			color: user?.color || "gray",
			left: mouse.x * window.innerWidth,
			top: (mouse.y * window.innerHeight) - 4,
		}}><span style={{ fontFamily: "'Noto Sans Symbols 2'" }}>🮰</span><span className="relative top-2 text-xs">{user?.name || socketid}</span></div>);
	}
	return <div className="fixed top-0 left-0 w-full h-full pointer-events-none">
		{mice}
	</div>;
}
