




export function ProgressBar({ progress }) {
	if (progress !== null) return <div className="w-full h-1" style={{ backgroundColor: "red" }}>
		<div className="h-full" style={{ backgroundColor: "lime", width: `${progress * 100}%` }}></div>
	</div>;
}
