import {useState} from "react";

export function InitPage({updateUser}) {

	var [name, setName] = useState(null);
	var [color, setColor] = useState(random_color());

	function onSubmit(event) {
		event.preventDefault();
		updateUser({name, color});
	}

	return <div className="InitPage p-3">
		<div className="">
			<form onSubmit={onSubmit}>
				<div className="m-1">welcome to chat app</div>
				<div className="m-1">
				<label htmlFor="name" className="mr-2">enter name:</label>
				<input type="text" id="name" className="dark:bg-black border p-1" value={name} onChange={event => setName(event.target.value)} />
				</div>
				<div className="m-1">
				<label htmlFor="color" className="mr-2">choose color:</label>
				<input type="color" id="color" value={color} onChange={event => setColor(event.target.value)} />
				</div>
				<div className="m-1"><input type="submit" className="border p-1" value="ok go"></input></div>
			</form>
		</div>
	</div>
}

function random_color() {
	var letters = '0123456789ABCDEF'.split('');
	var color = '#';
	for (let i = 0; i < 6; i++) color += letters[Math.floor(Math.random() * 16)];
	return color;
}