import { useEffect, useState } from 'react';
import { SERVER_BASE_URL } from './App';






export function EmojiPicker({ open, setOpen, appendToTextInput }) {
	var [emojis, setEmojis] = useState([]);
	var getEmojis = () => fetch(SERVER_BASE_URL + "/emojis").then(r => r.json());
	useEffect(() => { open && getEmojis().then(setEmojis); }, [open]);
	var onEmojiClick = event => {
		//setOpen(false);
		appendToTextInput(`:${event.target.dataset.emoji}:`);
	};
	if (open)
		return <div className='fixed top-0 left-0 w-full h-full' onClick={e => setOpen(false)}>
			<div className='w-[258px] h-64 rounded border fixed right-6 bottom-16 overflow-auto bg-white dark:bg-black p-2' onClick={e => e.stopPropagation()}>
				{emojis.map(emoji => <img src={SERVER_BASE_URL + "/emoji/" + emoji} title={`:${emoji}:`} alt={`:${emoji}:`} key={emoji} data-emoji={emoji} className="w-10 h-10 inline-block p-0.5 cursor-pointer border border-transparent hover:border-gray-600" onClick={onEmojiClick} />)}
			</div>
		</div>;
}
