import { useEffect, memo } from 'react';






export var UserList = memo(function UserList({ users, updateUser, user: myUser, socket }) {
	function changeName() {
		var name = prompt("Change name", myUser.name);
		if (!name) return;
		updateUser({ name });
	}
	useEffect(() => {
		socket?.on("type", socketid => {
			var n = document.getElementById(socketid);
			n.style.bottom = "2px";
			setTimeout(() => n.style.bottom = "0px", 100);
		});
	}, [socket]);
	return <div className="fixed top-0 right-5 text-right max-w-xs">
		<b>{users.length}</b> online: {users.map(user => <div style={{ color: user.color }} key={user.socketid} id={user.socketid} className={"mr-1 inline-block relative" + (user.socketid === socket.id ? ' cursor-pointer	' : '')} onClick={user.socketid === socket.id ? changeName : undefined}>{user.name}</div>)}
	</div>;
});
